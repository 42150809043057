function Idiomas() {
    return (
        <section id="sec_idiomas" className='border shadow-def m-9 md:m-4 rounded-xl shadow-secondary-100  md:col-start-5 md:col-end-8 md:row-start-3 md:row-end-4'>

            <h1 className='text-2xl text-secondary-200 font-bold m-4 mt-8 text-center md:text-3xl '>Idiomas</h1>


            <ol className="relative ml-8  border-s mb-14  border-gray-700 ">
                <li className="mb-10 ms-6 mx-4">
                    <span className="absolute flex items-center justify-center w-8 h-8  rounded-full -start-4 ring-4  ring-secondary-700 bg-secondary-300">
                        <svg className="w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="translate-language"><path fill="white" d="M7.01 10a.505.505 0 0 0 .405-.227.526.526 0 0 0 .055-.471c-.144-.396-.796-.396-.939 0l-.01.027a.504.504 0 0 0 .069.458.521.521 0 0 0 .42.213z"></path><path fill="white" d="M17.407 19.791a.496.496 0 0 0 .066-.452l-6.5-19A.5.5 0 0 0 10.5 0h-8A2.503 2.503 0 0 0 0 2.5v15C0 18.878 1.122 20 2.5 20H17a.5.5 0 0 0 .407-.209zM4.029 13.332l2.5-7c.142-.396.801-.396.942 0l2.5 7a.502.502 0 0 1-.302.639.503.503 0 0 1-.64-.302l-.834-2.336A.5.5 0 0 0 7.724 11H6.276a.5.5 0 0 0-.471.332l-.835 2.336a.504.504 0 0 1-.639.303.502.502 0 0 1-.302-.639zM16.312 12.626a.487.487 0 0 1 .151.185c.099.245.31.563.664.999a.499.499 0 0 0 .389.186h.016a.503.503 0 0 0 .392-.21c.476-.67.904-1.409 1.206-2.08a.503.503 0 0 0-.457-.706h-2.296a.5.5 0 0 0-.473.661l.253.741c.03.088.084.165.155.224z"></path><path fill="white" d="M21.5 4h-7.518a.5.5 0 0 0-.473.661l1.71 5c.07.203.26.339.473.339h.808a.5.5 0 0 0 .5-.5.5.5 0 0 1 1 0 .5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-.695a.5.5 0 0 0-.478.354c-.303.989-.992 2.242-1.844 3.353a.5.5 0 0 0 .037.652 44.5 44.5 0 0 0 1.817 1.773.498.498 0 0 1 .031.705.512.512 0 0 1-.707.031c-.164-.15-.512-.473-.939-.888a.499.499 0 0 0-.822.52l.518 1.514A1.499 1.499 0 0 1 17 21h-4.25a.5.5 0 0 0-.468.675l.75 2A.5.5 0 0 0 13.5 24h8c1.378 0 2.5-1.122 2.5-2.5v-15C24 5.122 22.878 4 21.5 4z"></path></svg>
                    </span>
                    <h3 className="font-medium leading-tight  text-secondary-150 ">Español</h3>

                </li>
                <li className="ms-6 mx-4">
                    <span className="absolute flex items-center justify-center w-8 h-8 rounded-full -start-4 ring-4  ring-secondary-700 bg-secondary-300">
                        <svg className="w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="translate-language"><path fill="white" d="M7.01 10a.505.505 0 0 0 .405-.227.526.526 0 0 0 .055-.471c-.144-.396-.796-.396-.939 0l-.01.027a.504.504 0 0 0 .069.458.521.521 0 0 0 .42.213z"></path><path fill="white" d="M17.407 19.791a.496.496 0 0 0 .066-.452l-6.5-19A.5.5 0 0 0 10.5 0h-8A2.503 2.503 0 0 0 0 2.5v15C0 18.878 1.122 20 2.5 20H17a.5.5 0 0 0 .407-.209zM4.029 13.332l2.5-7c.142-.396.801-.396.942 0l2.5 7a.502.502 0 0 1-.302.639.503.503 0 0 1-.64-.302l-.834-2.336A.5.5 0 0 0 7.724 11H6.276a.5.5 0 0 0-.471.332l-.835 2.336a.504.504 0 0 1-.639.303.502.502 0 0 1-.302-.639zM16.312 12.626a.487.487 0 0 1 .151.185c.099.245.31.563.664.999a.499.499 0 0 0 .389.186h.016a.503.503 0 0 0 .392-.21c.476-.67.904-1.409 1.206-2.08a.503.503 0 0 0-.457-.706h-2.296a.5.5 0 0 0-.473.661l.253.741c.03.088.084.165.155.224z"></path><path fill="white" d="M21.5 4h-7.518a.5.5 0 0 0-.473.661l1.71 5c.07.203.26.339.473.339h.808a.5.5 0 0 0 .5-.5.5.5 0 0 1 1 0 .5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-.695a.5.5 0 0 0-.478.354c-.303.989-.992 2.242-1.844 3.353a.5.5 0 0 0 .037.652 44.5 44.5 0 0 0 1.817 1.773.498.498 0 0 1 .031.705.512.512 0 0 1-.707.031c-.164-.15-.512-.473-.939-.888a.499.499 0 0 0-.822.52l.518 1.514A1.499 1.499 0 0 1 17 21h-4.25a.5.5 0 0 0-.468.675l.75 2A.5.5 0 0 0 13.5 24h8c1.378 0 2.5-1.122 2.5-2.5v-15C24 5.122 22.878 4 21.5 4z"></path></svg>
                    </span>
                    <h3 className="font-medium leading-tight  text-secondary-150 ">Ingles Intermedio</h3>

                </li>
            </ol>



        </section>
    )
}
export default Idiomas;

